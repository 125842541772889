import React, { useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, EffectCoverflow, Navigation } from "swiper/core"
import VideoPlayer from "../../reusable/VideoPlayer"
import LeftArrow from "../../../assets/svg/LeftArrow.svg"
import RightArrow from "../../../assets/svg/rightArrow.svg"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import { graphql, useStaticQuery } from "gatsby"

const SwiperContainer = styled(FlexContainer)`
  max-width: 1294px;
  margin-inline: auto;
  padding: 0;
  .not-visible {
    visibility: hidden;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 500px;
    height: 300px;
    > div {
      display: block;
      width: 100%;
    }
  }
`

const LeftArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 20;
  cursor: pointer;
  transform: translate(0, -50%);
  @media (max-width: ${({ theme }) => theme.mobile}) {
    > svg {
      width: 15px;
      height: 20px;
    }
  }
`

const RightArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 20;
  cursor: pointer;
  transform: translate(0, -50%);
  @media (max-width: ${({ theme }) => theme.mobile}) {
    > svg {
      width: 15px;
      height: 20px;
    }
  }
`

const query = graphql`
  {
    youTubeLinks: allContentfulBackStageVideos(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        youtubeLink
      }
    }
  }
`

const BackStage = () => {
  const {
    youTubeLinks: { nodes },
  } = useStaticQuery(query)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <>
      <SwiperContainer>
        <Swiper
          modules={[Pagination, EffectCoverflow, Navigation]}
          effect={"coverflow"}
          grabCursor={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          centeredSlides={true}
          breakpoints={{
            100: {
              slidesPerView: 1,
            },
            700: {
              slidesPerView: 2,
            },
            1440: {
              slidesPerView: 2,
            },
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 3,
            depth: 100,
            modifier: 3,
            slideShadows: false,
          }}
          pagination={false}
        >
          {nodes.map(({ youtubeLink, id }) => (
            <SwiperSlide key={id} id={id}>
              <VideoPlayer url={youtubeLink} />
            </SwiperSlide>
          ))}
          <LeftArrowContainer ref={navigationPrevRef}>
            <LeftArrow />
          </LeftArrowContainer>
          <RightArrowContainer ref={navigationNextRef}>
            <RightArrow />
          </RightArrowContainer>
        </Swiper>
      </SwiperContainer>
    </>
  )
}

export default BackStage

import React from "react"
import LayoutRoot from "../components/LayoutRoot"
import SectionOne from "../components/AboutUs/SectionOne/SectionOne"
import OurServices from "../components/reusable/OurServices"
import SectionTree from "../components/AboutUs/SectionThree/SectionTree"
import Seo from "../components/seo"
import SectionFour from "../components/AboutUs/SectionFour/SectionFour"
import SectionFive from "../components/AboutUs/SectionFive/SectionFive"

const AboutUsPage = () => {
  return (
    <LayoutRoot>
      <Seo title="About-Us" />
      <SectionOne />
      <OurServices withAbout={false} />
      <SectionTree />
      <SectionFour />
      <SectionFive />
    </LayoutRoot>
  )
}

export default AboutUsPage

import React, { useRef, useState } from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import { P } from "../../../styles/shared.styled"
import TeamCard from "./TeamCard"
import TeamCardDetailed from "./TeamCardDetailed"
import { useInView } from "react-intersection-observer"
import useViewMore from "../../../hooks/useViewMore"
import { graphql, useStaticQuery } from "gatsby"

const SectionFourContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.white};
  flex-direction: column;
  padding: 32px 14px;
`

const TeamCardContainer = styled(FlexContainer)`
  max-width: 1294px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 0;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
  }
`

const StyledP = styled(P)`
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0)" : "translateX(200px)"};
  transition: all 0.9s ease;
`

const ViewMore = styled.button`
  margin-top: 24px;
  border: none;
  color: ${({ theme }) => theme.lightBlue};
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0)")};
  transition: all 1s ease;
  &:hover {
    text-decoration: underline;
  }
`

const OurTeam = (setTrigger, trigger, setDetails, data) => (
  <>
    {data.map(member => (
      <TeamCard
        key={member.id}
        member={member}
        setTrigger={setTrigger}
        trigger={trigger}
        setDetails={setDetails}
      />
    ))}
  </>
)

const SectionFour = () => {
  const {
    allContentfulOurTeam: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulOurTeam(sort: { fields: createdAt, order: ASC }) {
        nodes {
          id
          employeeName
          position
          employeePicture {
            fluid(maxWidth: 306) {
              src
              base64
              aspectRatio
              srcSet
              sizes
            }
          }
        }
      }
    }
  `)

  const [trigger, setTrigger] = useState(false)
  const [details, setDetails] = useState({})
  const [slicedData, handleOnViewMore, slicedDataLength] = useViewMore(nodes, 3)
  const containerRef = useRef(null)

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })
  const { ref: viewMoreRef, inView: viewMoreInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })

  return (
    <SectionFourContainer>
      <StyledP ref={ref} inView={inView} margin="0 0 20px">
        OUR TEAM
      </StyledP>
      <StyledP ref={ref} inView={inView} fontSize="24px" fontWeight="bold">
        WE ARE CREATORS, INNOVATORS, DESIGNERS AND LEADERS.
      </StyledP>
      <TeamCardContainer ref={containerRef}>
        {OurTeam(setTrigger, trigger, setDetails, slicedData)}
      </TeamCardContainer>
      <TeamCardDetailed
        details={details}
        setTrigger={setTrigger}
        trigger={trigger}
      />
      {slicedDataLength === nodes.length ? null : (
        <ViewMore
          ref={viewMoreRef}
          inView={viewMoreInView}
          onClick={() => handleOnViewMore(prev => prev + 3)}
        >
          View More
        </ViewMore>
      )}
    </SectionFourContainer>
  )
}

export default SectionFour

import { useEffect, useMemo, useState } from "react"

export default function useViewMore(data, itemToShow) {
  const [slicedData, setSlicedData] = useState(() => data.slice(0, itemToShow))
  const [count, setCount] = useState(itemToShow)

  const handleOnViewMore = callback => {
    setCount(callback)
  }
  const memoizedSlicedData = useMemo(() => {
    return data.slice(0, count)
  }, [count, data])

  useEffect(() => {
    setSlicedData(memoizedSlicedData)
  }, [count, memoizedSlicedData])

  return [slicedData, handleOnViewMore, slicedData.length]
}

import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import { P } from "../../../styles/shared.styled"
import BackStage from "./BackStage"
import CompanyLogos from "./CompanyLogos"
import { useInView } from "react-intersection-observer"

const SectionFiveContainer = styled(FlexContainer)`
  flex-direction: column;
  padding: 32px 14px;
  gap: 32px;
`

const TextContainer = styled(FlexContainer)`
  max-width: 750px;
  flex-direction: column;
`

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme: { white } }) => `${white}CC`};
`

const StyledP = styled(P)`
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
          inView ? "translateX(0)" : "translateX(-200px)"};
  transition: all 0.9s ease;
`

const SectionFive = () => {
  const { ref: backStageRef, inView: backStageInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false
  })

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false
  })

  return (
    <SectionFiveContainer>
      <FlexContainer flexDirection="column" gap="14px">
        <StyledP
          ref={backStageRef}
          inView={backStageInView}
          color="white"
          margin="0"
        >
          WATCH VIDEO
        </StyledP>
        <StyledP
          ref={backStageRef}
          inView={backStageInView}
          color="white"
          fontWeight="bold"
          fontSize="24px"
        >
          BACKSTAGE VIDEOS
        </StyledP>
      </FlexContainer>
      <BackStage />
      <CompanyLogos />
      <FlexContainer flexDirection="column" gap="14px">
        <StyledP ref={ref} inView={inView} color="white" margin="0">
          PRICING
        </StyledP>
        <StyledP
          ref={ref}
          inView={inView}
          color="white"
          fontWeight="bold"
          fontSize="24px"
        >
          WHAT DOES IT COST?
        </StyledP>
        <TextContainer>
          <Text>
            This is the question we are asked most frequently. We wish we could
            give you a rubric that says exactly what each video costs, every time.
            But, we’re not making inelastic products with the same repeatable
            actions.
          </Text>
          <Text>
            We suggest that you figure out broadly what type of video you are
            interested in making and a budget that you can spend on it. Then,
            share that information with us. From there, we will write a script
            that stays within that budget.
          </Text>
          <Text>
            Additionally, we are transparent in our breakdown of costs so that you
            will understand how the budget is being spent.
          </Text>
        </TextContainer>
      </FlexContainer>
    </SectionFiveContainer>
  )
}

export default SectionFive

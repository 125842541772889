import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import Counter from "./Counter"
import { YEAR } from "../../../constants"
import { useInView } from "react-intersection-observer"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const SectionThreeContainer = styled(FlexContainer)`
    aspect-ratio: 4.2;
`

const Content = styled(FlexContainer)`
    max-width: 1294px;
    padding: 32px 14px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
        flex-direction: column;
    }
`
const RightPartContainer = styled(FlexContainer)`
    transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0)")};
    opacity: ${({ inView }) => (inView ? "1" : "0")};
    transition: all 1s ease;
`

const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 18px;
    color: ${({ theme }) => theme.white};
    @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 18px;
    }
`

const Caption = styled.p`
    font-size: 18px;
    font-weight: 100;
    line-height: 21px;
    margin: 0;
    color: ${({ theme }) => `${theme.white}CC`};
    @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
        text-align: center;
    }
    @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
        text-align: center;
        font-size: 14px;
    }
`

const SectionTree = () => {
  const { theyTrustUs, clientInfo } = useStaticQuery(graphql`
    {
      theyTrustUs: contentfulWebsiteImages(title: { eq: "They Trust us" }) {
        image {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
       clientInfo: allContentfulTheyTrustUs {
     nodes {
      workDone
       happyClients
    }
   }
    }
  `)

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false
  })

  return (
    <SectionThreeContainer>
      <Image
        fluid={theyTrustUs.image.fluid}
        loading="auto"
        style={{
          height: "100%",
          width: "100%",
          position: "absolute"
        }}
      />
      <Content>
        <FlexContainer width="50%" tabletWidth="100%">
          <Counter value={YEAR} title="YEAR" />
          <Counter value={clientInfo?.nodes[0]?.workDone} title="WORK DONE" />
          <Counter value={clientInfo?.nodes[0]?.happyClients} title="HAPPY CLIENTS" />
        </FlexContainer>
        <RightPartContainer
          flexDirection="column"
          width="35%"
          tabletWidth="100%"
          tabletMargin="40px 0 0"
          alignItems="flex-start"
          tabletAlignItems="center"
          ref={ref}
          inView={inView}
        >
          <Title>THEY TRUST US</Title>
          <Caption>
            The work isn't done when we are happy; <br />
            The work is done when our client is smiling.
          </Caption>
        </RightPartContainer>
      </Content>
    </SectionThreeContainer>
  )
}

export default SectionTree

import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import CloseIcon from "../../../assets/svg/CloseIcon.svg"
import { FlexContainer } from "../../../styles/layout.styled"
import useClickOutside from "../../../hooks/useClickOutside"
import useHideScrollbar from "../../../hooks/useHideScrollbar"

const MainContainer = styled(FlexContainer)`
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 900px;
  z-index: 10;
  background: ${({ theme }) => theme.white};
  box-shadow: 0 0 34px rgba(18, 36, 54, 0.26);
  border-radius: 10px;
  opacity: ${({ trigger }) => (trigger ? "1" : "0")};
  visibility: ${({ trigger }) => (trigger ? "visible" : "hidden")};
  transition: all 0.3s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: unset;
    visibility: ${({ trigger }) => (trigger ? "visible" : "hidden")};
    opacity: ${({ trigger }) => (trigger ? "1" : "0")};
    transform: ${({ trigger }) =>
      trigger ? "translate(0px, 0)" : "translate(0px, 200px)"};
  }
`

const ContentContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  > svg {
    width: 14px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 23px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
    overflow: auto;
    padding: 32px 20px;

    > svg {
      top: 15px;
      right: 10px;
    }
  }
`

const RightSide = styled(FlexContainer)`
  width: 60%;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const LeftSide = styled(FlexContainer)`
  width: 37%;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 50%;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const Image = styled(FlexContainer)`
  background: #c4c4c4 ${({ profilePic }) => `url(${profilePic})`} no-repeat;
  background-size: cover;
  width: 306px;
  height: 306px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`

const YearsWithMegContainer = styled(FlexContainer)`
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid ${({ theme }) => theme.orange};
  box-sizing: border-box;
  width: 102px;
  height: 90px;
  border-radius: 10px 0;
  flex-direction: column;
  align-items: center;
`

const H6 = styled.h6`
  color: ${({ theme }) => theme.orange};
  font-size: 48px;
  font-weight: bold;
  margin: 0;
`
const YearsWithMeg = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.white};
  font-weight: 100;
`

const Name = styled.h6`
  color: ${({ theme }) => theme.lightBlue};
  font-weight: bold;
  font-size: 18px;
  margin: 0;
`

const Position = styled.span`
  color: ${({ theme }) => theme.lightBlue};
  font-weight: 100;
  font-size: 14px;
  margin: 12px 0 25px;
`

const Title = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.lightBlue};
  font-weight: bold;
  margin: ${({ margin }) => margin || "0 0 12px"};
`
const Value = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.lightBlue};
  font-weight: 100;
`

const TeamCardDetailed = ({ trigger, setTrigger, details }) => {
  const ref = useRef(null)
  const contentRef = useRef(null)
  useClickOutside(ref, () => setTrigger(false))
  useHideScrollbar(trigger)
  useEffect(() => {
    if (trigger) {
      contentRef.current.scrollTop = 0
    }
  }, [trigger])
  const {
    name,
    position,
    favoritePart,
    favoritePlace,
    liveWithout,
    describeMe,
    favoriteQuote,
    otherThanWork,
    profilePic,
  } = details

  return (
    <MainContainer trigger={trigger} ref={ref}>
      <ContentContainer ref={contentRef}>
        <CloseIcon onClick={() => setTrigger(false)} />
        <LeftSide>
          <Image profilePic={profilePic}>
            <YearsWithMegContainer>
              <H6>2</H6>
              <YearsWithMeg>YEARS WITH MEG</YearsWithMeg>
            </YearsWithMegContainer>
          </Image>
        </LeftSide>
        <RightSide>
          <Name>{name?.toUpperCase()}</Name>
          <Position>{position?.toUpperCase()}</Position>
          <Title>
            FAVORITE PART OF THE JOB: <Value>{favoritePart}</Value>
          </Title>
          <Title>
            Favorite PLACE TRAVELED TO: <Value>{favoritePlace}</Value>
          </Title>
          <Title>
            CAN’T LIVE WITHOUT: <Value>{liveWithout}</Value>
          </Title>
          <Title>
            3 WORDS THAT DESCRIBE ME: <Value>{describeMe}</Value>
          </Title>
          <Title>
            Favorite quote: <Value>{favoriteQuote}</Value>
          </Title>
          <Title margin="0">
            OTHER THAN WORK I ENJOY: <Value>{otherThanWork}</Value>
          </Title>
        </RightSide>
      </ContentContainer>
    </MainContainer>
  )
}

export default TeamCardDetailed
